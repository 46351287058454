import React from 'react';
import { navigate } from 'gatsby';

export function getPreviewText(gameVersionId) {
  switch (gameVersionId) {
    case '1':
      return (
        <div>
          <h2>Holly Halls Hopper</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '2':
      return (
        <div>
          <h2>Birthday Bonanza</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '3':
      return (
        <div>
          <h2>To Birthday And Beyond</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '4':
      return (
        <div>
          <h2>Workshop Dash</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '5':
      return (
        <div>
          <h2>Adventures Of You</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '6':
      return (
        <div>
          <h2>Beat The Ball Drop</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '7':
      return (
        <div>
          <h2>Cupids Gauntlet</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '8':
      return (
        <div>
          <h2>Birthday Derby</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '9':
      return (
        <div>
          <h2>Christmas Slider</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '10':
      return (
        <div>
          <h2>Beach Birthday</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '11':
      return (
        <div>
          <h2>Valentines Date Dash</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    case '12':
      return (
        <div>
          <h2>Princess Yous Perils</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
    default:
      return (
        <div>
          <h2>In a land of myth and a time of magic something something, his name, Merlin!</h2>
          <ul style={{ textAlign: 'left' }}>
            <li>One Great Ozge Thing</li>
            <li>Something Else</li>
            <li>Wow, A Third Thing!</li>
          </ul>
        </div>
      );
  }
}

export function redirectGame(gameVersionId) {
  switch (gameVersionId) {
    case '1':
      navigate('/game-preview/holly-halls-hopper');
      break;
    case '2':
      navigate('/game-preview/birthday-bonanza');
      break;
    case '3':
      navigate('/game-preview/to-birthday-and-beyond');
      break;
    case '4':
      navigate('/game-preview/workshop-dash');
      break;
    case '5':
      navigate('/game-preview/adventures-of-you');
      break;
    case '6':
      navigate('/game-preview/beat-the-ball-drop');
      break;
    case '7':
      navigate('/game-preview/cupids-gauntlet');
      break;
    case '8':
      navigate('/game-preview/birthday-derby');
      break;
    case '9':
      navigate('/game-preview/christmas-slider');
      break;
    case '10':
      navigate('/game-preview/beach-birthday');
      break;
    case '11':
      navigate('/game-preview/valentines-date-dash');
      break;
    case '12':
      navigate('/game-preview/princess-yous-perils');
      break;
    default:
      navigate('/');
  }
}

export function getLevelTextTemplate(gameVersionId, heroName, cityName, trophy, villianName) {
  switch (gameVersionId) {
    case '1':
      return (
        <div>{gameVersionId}</div>
      );
    case '2':
      return (
        <div>{gameVersionId}</div>
      );
    case '3':
      return (
        <div>{gameVersionId}</div>
      );
    case '4':
      return (
        <div>{gameVersionId}</div>
      );
    case '5':
      return (
        <th width="50%">
          <h4>Level 1 Text</h4>
          <p>
            It was a cold and stormy day when
            <span style={{ color: 'orange' }}>{heroName}</span>
            {' '}
            set out from
            <span style={{ color: 'orange' }}>{cityName}</span>
            {' '}
            in search of
            <span style={{ color: 'orange' }}>{trophy}</span>
            . Soon enough the evil
            <span style={{ color: 'orange' }}>{villianName}</span>
            {' '}
            was being evil
          </p>
          <h4>Level 2 Text</h4>
          <p>
            <span style={{ color: 'orange' }}>{heroName}</span>
            {' '}
            saw
            {' '}
            <span style={{ color: 'orange' }}>{villianName}</span>
            {' '}
            hiding near the
            {' '}
            <span style={{ color: 'orange' }}>{trophy}</span>
            . Stop right there
            {' '}
            <span style={{ color: 'orange' }}>{villianName}</span>
            {' '}
            yelled
            {' '}
            <span style={{ color: 'orange' }}>{heroName}</span>
          </p>
          <h4>Level 3 Text</h4>
          <p>
            <span style={{ color: 'orange' }}>{heroName}</span>
            {' '}
            finally found the
            {' '}
            <span style={{ color: 'orange' }}>{trophy}</span>
            . In the swamps outside of
            {' '}
            <span style={{ color: 'orange' }}>{cityName}</span>
            {' '}
            the evil
            {' '}
            <span style={{ color: 'orange' }}>{villianName}</span>
            {' '}
            went home and cried for 6 days.
            {' '}
            <span style={{ color: 'orange' }}>{heroName}</span>
            {' '}
            got ice cream
          </p>
        </th>
      );
    case '6':
      return (
        <div>{gameVersionId}</div>
      );
    case '7':
      return (
        <div>{gameVersionId}</div>
      );
    case '8':
      return (
        <div>{gameVersionId}</div>
      );
    case '9':
      return (
        <div>{gameVersionId}</div>
      );
    case '10':
      return (
        <div>{gameVersionId}</div>
      );
    case '11':
      return (
        <div>{gameVersionId}</div>
      );
    case '12':
      return (
        <div>{gameVersionId}</div>
      );
    default:
      return (
        <div>{gameVersionId}</div>
      );
  }
}
