import Stand from '../../../public/images/standingMan.png';
import GingerbreadStand from '../../../public/images/gingerbread-standing.png';
import SpaceStand from '../../../public/images/spacesuit-stand.png';
import ElfStand from '../../../public/images/elf-stand.png';
import RacecarDriverStand from '../../../public/images/racecar-driver-stand.png';
import SantaStand from '../../../public/images/santa-stand.png';
import SwimsuitStand from '../../../public/images/male-swimsuit-stand.png';
import PrincessStand from '../../../public/images/princess-stand.png';

export default (gameVersionId) => {
  switch (gameVersionId) {
    case '1':
      return GingerbreadStand;
    case '2':
    case '5':
    case '6':
    case '7':
    case '11':
      return Stand;
    case '3':
      return SpaceStand;
    case '4':
      return ElfStand;
    case '8':
      return RacecarDriverStand;
    case '9':
      return SantaStand;
    case '10':
      return SwimsuitStand;
    case '12':
      return PrincessStand;
    default:
      return Stand;
  }
};
