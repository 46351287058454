import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout';
import PlayerGenerator from '../components/player-generator';
import LevelTextGenerator from '../components/level-text-generator';
import VillianGenerator from '../components/villian-generator';

const GameGenerationRouter = () => (
  <Layout>
    <Router>
      {/* holly halls hopper */}
      <PlayerGenerator path="/generate-game/holly-halls-hopper/player" redirect="/generate-game/holly-halls-hopper/level-text" gameVersionId="1" />
      <LevelTextGenerator path="/generate-game/holly-halls-hopper/level-text" gameVersionId="1" />
      {/* birthday bonanza */}
      <PlayerGenerator exact path="/generate-game/birthday-bonanza/player" redirect="/generate-game/birthday-bonanza/level-text" gameVersionId="2" />
      <LevelTextGenerator path="/generate-game/birthday-bonanza/level-text" redirect="/generate-game/birthday-bonanza/villian" gameVersionId="2" />
      <VillianGenerator path="/generate-game/birthday-bonanza/villian" gameVersionId="2" />
      {/* to birthday and beyond */}
      <PlayerGenerator exact path="/generate-game/to-birthday-and-beyond/player" redirect="/generate-game/to-birthday-and-beyond/level-text" gameVersionId="3" />
      <LevelTextGenerator path="/generate-game/to-birthday-and-beyond/level-text" redirect="/generate-game/to-birthday-and-beyond/villian" gameVersionId="3" />
      <VillianGenerator path="/generate-game/to-birthday-and-beyond/villian" gameVersionId="3" />
      {/* workshop dash */}
      <PlayerGenerator exact path="/generate-game/workshop-dash/player" redirect="/generate-game/workshop-dash/level-text" gameVersionId="4" />
      <LevelTextGenerator path="/generate-game/workshop-dash/level-text" gameVersionId="4" />
      {/* adventures of you */}
      <PlayerGenerator exact path="/generate-game/adventures-of-you/player" redirect="/generate-game/adventures-of-you/level-text" gameVersionId="5" />
      <LevelTextGenerator path="/generate-game/adventures-of-you/level-text" redirect="/generate-game/adventures-of-you/villian" gameVersionId="5" />
      <VillianGenerator path="/generate-game/adventures-of-you/villian" gameVersionId="5" />
      {/* beat the ball drop */}
      <PlayerGenerator exact path="/generate-game/beat-the-ball-drop/player" redirect="/generate-game/beat-the-ball-drop/level-text" gameVersionId="6" />
      <LevelTextGenerator path="/generate-game/beat-the-ball-drop/level-text" gameVersionId="6" />
      {/* cupids gauntlet */}
      <PlayerGenerator exact path="/generate-game/cupids-gauntlet/player" redirect="/generate-game/cupids-gauntlet/level-text" gameVersionId="7" />
      <LevelTextGenerator path="/generate-game/cupids-gauntlet/level-text" redirect="/generate-game/cupids-gauntlet/villian" gameVersionId="7" />
      <VillianGenerator path="/generate-game/cupids-gauntlet/villian" gameVersionId="7" />
      {/* birthday derby */}
      <PlayerGenerator exact path="/generate-game/birthday-derby/player" redirect="/generate-game/birthday-derby/level-text" gameVersionId="8" />
      <LevelTextGenerator path="/generate-game/birthday-derby/level-text" redirect="/generate-game/birthday-derby/villian" gameVersionId="8" />
      <VillianGenerator path="/generate-game/birthday-derby/villian" gameVersionId="8" />
      {/* christmas slider */}
      <PlayerGenerator exact path="/generate-game/christmas-slider/player" redirect="/generate-game/christmas-slider/level-text" gameVersionId="9" />
      <LevelTextGenerator path="/generate-game/christmas-slider/level-text" redirect="/generate-game/christmas-slider/villian" gameVersionId="9" />
      {/* beach birthday */}
      <PlayerGenerator exact path="/generate-game/beach-birthday/player" redirect="/generate-game/beach-birthday/level-text" gameVersionId="10" />
      <LevelTextGenerator path="/generate-game/beach-birthday/level-text" gameVersionId="10" />
      <VillianGenerator path="/generate-game/beach-birthday/villian" gameVersionId="10" />
      {/* valentines date dash */}
      <PlayerGenerator exact path="/generate-game/valentines-date-dash/player" redirect="/generate-game/valentines-date-dash/level-text" gameVersionId="11" />
      <LevelTextGenerator path="/generate-game/valentines-date-dash/level-text" redirect="/generate-game/valentines-date-dash/villian" gameVersionId="11" />
      {/* princess yous perils */}
      <PlayerGenerator exact path="/generate-game/princess-yous-perils/player" redirect="/generate-game/princess-yous-perils/level-text" gameVersionId="12" />
      <LevelTextGenerator path="/generate-game/princess-yous-perils/level-text" redirect="/generate-game/princess-yous-perils/villian" gameVersionId="12" />
      <VillianGenerator path="/generate-game/princess-yous-perils/villian" gameVersionId="12" />
    </Router>
  </Layout>
);

export default GameGenerationRouter;
