import { v1 as uuidv1 } from 'uuid';
import { navigate } from 'gatsby';
import config from '../config';
import { getUser } from '../../services/auth';

export function createGameHero(formData, gameId, gameVersionId, redirect) {
  fetch(`${config.backend}/client-image`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((fileResData) => {
      const imageUrls = fileResData.filePaths;
      return fetch(`${config.backend}/game/create-game-hero`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
          newId: uuidv1(),
          gameId,
          gameVersionId,
          heroImage: imageUrls[0],
        }),
      });
    })
    .then((res) => res.json())
    .then((resData) => {
      if (resData.errors && resData.errors[0].status === 422) {
        throw new Error(
          "Validation failed. Make sure the email address isn't used yet!",
        );
      }
      if (resData.errors) {
        throw new Error('User login failed!');
      }
      // set some cookies, for user and gameId
      localStorage.setItem('userId', resData.userId);
      localStorage.setItem('unfinishedGameId', resData.gameId);
      localStorage.setItem('unfinishedGameURL', `/secure/send/${resData.gameId}/${gameVersionId}`);
      navigate(redirect, {
        state: {
          userId: resData.userId,
          gameId: resData.gameId,
        },
      });
    });
}

export function createLevelText(
  level1text,
  level2text,
  level3text,
  gameId,
  gameVersionId,
  redirect,
) {
  fetch(`${config.backend}/game/create-game-text`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
      gameVersionId,
      gameId,
      level1text,
      level2text,
      finaltext: level3text,
    }),
  })
    .then((res) => res.json())
    .then((resData) => {
      if (resData.errors && resData.errors[0].status === 422) {
        throw new Error(
          "Validation failed. Make sure the email address isn't used yet!",
        );
      }
      if (resData.errors) {
        throw new Error('User login failed!');
      }
      if (redirect) {
        document.getElementById('pixopixa-villian-select').click();
      } else if (typeof window !== 'undefined' && !getUser()) {
        navigate(`/secure/send-game/${resData.gameId}/${gameVersionId}`, {
          state: {
            userId: resData.userId,
            gameId: resData.gameId,
          },
        });
      } else if (getUser()) {
        navigate(`/secure/send/${resData.gameId}/${gameVersionId}`, {
          state: {
            userId: resData.userId,
            gameId: resData.gameId,
          },
        });
      }
    });
}

export function createGameVillian(formData, gameId, gameVersionId) {
  fetch(`${config.backend}/client-image`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((fileResData) => {
      const imageUrls = fileResData.filePaths;
      return fetch(`${config.backend}/game/create-game-villian`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
          gameId,
          gameVersionId,
          villianImage: imageUrls[0],
        }),
      });
    })
    .then((res) => res.json())
    .then((resData) => {
      if (resData.errors && resData.errors[0].status === 422) {
        throw new Error(
          "Validation failed. Make sure the email address isn't used yet!",
        );
      }
      if (resData.errors) {
        throw new Error('User login failed!');
      }
      if (typeof window !== 'undefined' && !getUser()) {
        navigate(`/secure/send-game/${resData.gameId}/${gameVersionId}`, {
          state: {
            userId: resData.userId,
            gameId: resData.gameId,
          },
        });
      } else if (getUser()) {
        navigate(`/secure/send/${resData.gameId}/${gameVersionId}`, {
          state: {
            userId: resData.userId,
            gameId: resData.gameId,
          },
        });
      }
    });
}

export async function loadGame(gameId, gameVersionId) {
  // THIS FUNCTION NEEDS TO CHECK IF THE GAME IS PAID, OR ELSE i NEED A DIFFERENT FUNCTION TO USE ON
  // THE GAME PAGE? NO JUST DO EVERYTHING HERE! PROBABLY? YES!
  const res = await fetch(`${config.backend}/game/load-game`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      gameId,
      gameVersionId,
    }),
  });
  const resData = await res.json();
  if (resData.errors) {
    throw new Error('Fetching post failed!');
  }
  return resData;
}

export function emailGame(topText, receiverEmail, gameLink, gameVersionId, thumbnailUrl) {
  fetch(`${config.backend}/game/email-game`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      emailCopy: topText,
      receiverEmail,
      gameLink,
      gameVersionId,
      thumbnailUrl,
    }),
  })
    .then((res) => res.json())
    .then((resData) => {
      if (resData.errors) {
        throw new Error('Fetching post failed!');
      }
    // this.setState({})
    });
}
