import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { navigate } from 'gatsby';
import Stand from '../../public/images/villianStanding.png';
import { getUser } from '../services/auth';
import { createGameVillian } from './actions/game-actions';
import { redirectGame } from './actions/find-game-text';

class Game extends Component {
  constructor(props) {
    super(props);
    this.appendCharacterToCanvas = this.appendCharacterToCanvas.bind(this);
    this.setImageHandler = this.setImageHandler.bind(this);
    this.state = {
      gameId: uuidv1(),
      uncroppedImage: null,
      uncroppedImage2: null,
      villianURL: null,
      villianSliderValue: 1,
      villianRotaterValue: 0,
      faceOnly: null,
      villianDrag: false,
      mouseX: 0,
      mouseY: 0,
      villianImageOffsetX: 0,
      villianImageOffsetY: 0,
      villianImageOffsetXOld: 0,
      villianImageOffsetYOld: 0,
      villianImageOffsetXTotal: 0,
      villianImageOffsetYTotal: 0,
    };
  }

  componentDidMount() {
    if (!localStorage.getItem('unfinishedGameId') && (!this.props.location || !this.props.location.state || !this.props.location.state.villianImage)) {
      redirectGame(this.props.gameVersionId);
    } else if (localStorage.getItem('unfinishedGameId')) {
      this.setState({
        gameId: localStorage.getItem('unfinishedGameId'),
      });
    } else if (this.props.location.state.gameId) {
      this.setState({
        gameId: this.props.location.state.gameId,
      });
    }
    const overlayCanvas = document.getElementById('villian-cropper-overlay');
    overlayCanvas.addEventListener('mousedown', (e) => {
      this.setState({
        villianDrag: true,
        mouseX: e.clientX,
        mouseY: e.clientY,
      });
    });
    overlayCanvas.addEventListener('mouseup', () => {
      this.setState({
        villianDrag: false,
        villianImageOffsetX: 0,
        villianImageOffsetY: 0,
        villianImageOffsetXOld: 0,
        villianImageOffsetYOld: 0,
        villianImageOffsetXTotal: this.state.villianImageOffsetXTotal + this.state.villianImageOffsetX,
        villianImageOffsetYTotal: this.state.villianImageOffsetYTotal + this.state.villianImageOffsetY,
      });
    });
    overlayCanvas.addEventListener('mouseout', () => {
      this.setState({
        villianDrag: false,
        villianImageOffsetX: 0,
        villianImageOffsetY: 0,
        villianImageOffsetXOld: 0,
        villianImageOffsetYOld: 0,
        villianImageOffsetXTotal: this.state.villianImageOffsetXTotal + this.state.villianImageOffsetX,
        villianImageOffsetYTotal: this.state.villianImageOffsetYTotal + this.state.villianImageOffsetY,
      });
    });
    overlayCanvas.addEventListener('mousemove', (e) => {
      if (!this.state.villianDrag) return;

      this.setState({
        villianImageOffsetXOld: this.state.villianImageOffsetX,
        villianImageOffsetYOld: this.state.villianImageOffsetY,
        villianImageOffsetX: e.clientX - this.state.mouseX,
        villianImageOffsetY: e.clientY - this.state.mouseY,
      }, () => {
        this.sliderChangeHandler(this.state.villianSliderValue);
      });
    });
    overlayCanvas.addEventListener('wheel', (e) => {
      e.preventDefault();
      const villianSliderValue = e.deltaY > 0 ? this.state.villianSliderValue + 0.01 : this.state.villianSliderValue - 0.01;
      this.sliderChangeHandler(villianSliderValue);
    });
    this.setImageHandler(this.props.location.state.villianImage);
  }

  appendCharacterToCanvas() {
    this.setState({ sliderVisible: true });
    const mainCanvas = document.getElementById('villian-cropper');
    const overlayCanvas = document.getElementById('villian-cropper-overlay');
    const context = mainCanvas.getContext('2d');
    const imageObj = new Image();
    const imageObj2 = new Image();
    imageObj.onload = function (sliderValue) {
      // draw cropped image
      const destX = 0;
      const destY = 0;
      mainCanvas.width = 578;
      mainCanvas.height = 600;
      const destWidth = imageObj.width;
      const destHeight = imageObj.height;

      // context.clearRect(0, 0, heroCanvas.width, heroCanvas.height)
      context.drawImage(imageObj, destX, destY, destWidth, destHeight);
      overlayCanvas.width = 578;
      overlayCanvas.height = 600;
      const context2 = overlayCanvas.getContext('2d');
      // context2.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height)
      // canvas.width = imageObj.width;
      // canvas.height = imageObj.height;
      context2.beginPath();
      context2.rect(0, 0, overlayCanvas.width, overlayCanvas.height);
      context2.fillStyle = 'rgba(0, 0, 0, 0.8)';// rgba(10,10,10,0.7);
      context2.fill();

      // begin custom shape
      context2.beginPath();
      context2.moveTo(200, 80);
      context2.quadraticCurveTo(280, 80, 280, 160);
      context2.quadraticCurveTo(280, 320, 200, 320);
      context2.quadraticCurveTo(120, 320, 120, 160);
      context2.quadraticCurveTo(120, 80, 200, 80);

      // Append Character Body
      context2.drawImage(imageObj2, 70, 305, 260, 260);

      // complete custom shape
      context2.closePath();
      context2.lineWidth = 2;
      context2.strokeStyle = '#F4F4F4';
      context2.stroke();
      context2.globalCompositeOperation = 'destination-out';
      context2.fillStyle = 'rgba(255, 255, 255, 1)';
      context2.fill();

      // let ozge = overlayCanvas.toDataURL();
      // ozgeHandler(ozge)
    };
    imageObj.src = this.state.villianURL;
    imageObj2.src = Stand;
  }

  setImageHandler(image) {
    window.URL = window.URL || window.webkitURL;
    const fileURL = window.URL.createObjectURL(image);
    // Create a data URL from the image file
    this.setState({
      uncroppedImage2: image,
      villianURL: fileURL,
    }, () => {
      this.appendCharacterToCanvas();
    });
  }

  sliderChangeHandler(value, isRotater = false) {
    let rotaterValue = 0;
    let currentRotater2;
    let translateX;
    let translateY;
    let scalingFactor;
    let mainCanvas;
    // var xOffset
    // var yOffset
    mainCanvas = document.getElementById('villian-cropper');
    if (isRotater) {
      rotaterValue = parseFloat(value) > this.state.villianRotaterValue ? 1 : -1;
    }
    currentRotater2 = this.state.villianRotaterValue + rotaterValue;
    translateX = this.state.villianImageOffsetX - this.state.villianImageOffsetXOld;
    translateY = this.state.villianImageOffsetY - this.state.villianImageOffsetYOld;
    // xOffset = this.state.heroImageOffsetX + this.state.heroImageOffsetXTotal
    // yOffset = this.state.heroImageOffsetY + this.state.heroImageOffsetYTotal
    scalingFactor = isRotater === false ? parseFloat(value) : this.state.villianSliderValue;
    const currentRotater = this.state.villianRotaterValue + rotaterValue;
    // console.log(currentRotater)
    if (!isRotater) {
      this.setState({
        villianSliderValue: parseFloat(value),
      });
    } else {
      this.setState({
        villianRotaterValue: currentRotater,
      });
    }
    const context = mainCanvas.getContext('2d');
    // console.log(xOffset, yOffset)
    const imageObj = new Image();
    imageObj.onload = (e) => {
      context.clearRect(-400, -400, 2.5 * mainCanvas.width, 2.5 * mainCanvas.height);
      const destWidth = imageObj.width * scalingFactor;
      const destHeight = imageObj.height * scalingFactor;
      context.rotate(currentRotater2 * Math.PI / 180);
      context.drawImage(imageObj, 0, 0, destWidth, destHeight);
      context.rotate(-currentRotater2 * Math.PI / 180);
      context.translate(translateX, translateY);
    };
    imageObj.src = this.state.villianURL;
  }

  dataURLtoBlob(dataurl) {
    const arr = dataurl.split(','); const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]); let n = bstr.length; const
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  submitHandler() {
    const hiddenCanvas = document.getElementById('hidden-canvas');
    hiddenCanvas.width = 578;
    hiddenCanvas.height = 660;
    const context = hiddenCanvas.getContext('2d');
    context.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
    const imageObj = new Image();
    const imageObj2 = new Image();
    const scalingFactor = this.state.villianSliderValue;
    const xOffset = this.state.villianImageOffsetX + this.state.villianImageOffsetXTotal;
    const yOffset = this.state.villianImageOffsetY + this.state.villianImageOffsetYTotal;
    const { saveCanvasHandler } = this;
    imageObj.onload = (e) => {
      // let sx = imageObj.width * scalingFactor
      const destX = 0 + xOffset;
      const destY = 0 + yOffset;
      // var destX = -50 + Math.cos(currentRotater2 * Math.PI / 180) * xOffset + Math.sin(currentRotater2 * Math.PI /180) * yOffset;
      // var destY = -60 + Math.sin(currentRotater2 * Math.PI / 180) * yOffset - Math.cos(currentRotater2 * Math.PI / 180) * xOffset;
      const destWidth = imageObj.width * scalingFactor;
      const destHeight = imageObj.height * scalingFactor;
      /// Image 2
      context.drawImage(imageObj, destX, destY, destWidth, destHeight);
      context.beginPath();
      context.rect(0, 0, imageObj.width, imageObj.height);

      // begin custom shape
      context.beginPath();
      context.moveTo(200, 80); // transform = (-100, -60)
      context.quadraticCurveTo(280, 80, 280, 160);
      context.quadraticCurveTo(280, 320, 200, 320);
      context.quadraticCurveTo(120, 320, 120, 160);
      context.quadraticCurveTo(120, 80, 200, 80);

      // complete custom shape
      context.closePath();
      context.globalCompositeOperation = 'destination-in';
      context.fillStyle = 'rgba(255, 255, 255, 1)';
      context.fill();
      context.globalCompositeOperation = 'source-over';
      const faceOnly = hiddenCanvas.toDataURL();
      this.submitGameHandler(faceOnly);
    };
    imageObj.src = this.state.villianURL;
    imageObj2.src = Stand;
  }

  async submitGameHandler(faceOnly) {
    // if (this.validationHandler()) return
    const formData = new FormData();
    formData.append('gameId', this.state.gameId);
    formData.append('images', this.dataURLtoBlob(faceOnly), 'villianImage');
    await createGameVillian(formData, this.state.gameId, this.props.gameVersionId, this.props.redirect);
  }

  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '45%', padding: '2.5%' }}>
          <div style={{ position: 'relative' }}>
            <canvas id="villian-cropper" width="1" height="1" />
            <canvas
              id="villian-cropper-overlay"
              width="1"
              height="1"
              style={{
                position: 'absolute', left: '0', top: '0', zIndex: '100',
              }}
            />
          </div>
          <br />
          <canvas id="hidden-canvas" width="1" height="1" style={{ display: 'none' }} />
        </div>
        <div style={{ width: '50%', textAlign: 'center', padding: '10%' }}>
          <h2 className="filled-text-blue" style={{ fontSize: '3rem' }}>Cast Your Villian</h2>
          <div className="slidecontainer">
            <h3>Rotate</h3>
            <input type="range" min="-90" max="90" step="1" value={this.state.villianRotaterValue} className="round-slider" id="myRotationRange" onChange={(e) => this.sliderChangeHandler(e.target.value, true)} />
            <br />
            <h3>Resize</h3>
            <input type="range" min="0" max="15" step="0.01" value={this.state.villianSliderValue} className="slider" id="myRange" onChange={(e) => this.sliderChangeHandler(e.target.value)} />
          </div>
          <br />
          <button onClick={() => this.submitHandler()} className="cta-button">Create Villian!</button>
          {/* <button onClick={() => this.submitGameHandler()} disabled={!this.state.canSubmit}>{!this.state.canSubmit ? "Please Create Characters" : "Create Game!"}</button> */}
        </div>
      </div>
    );
  }
}

export default Game;
