import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { navigate } from 'gatsby';
import { getUser } from '../services/auth';
import { createLevelText } from './actions/game-actions';
import { redirectGame, getLevelTextTemplate } from './actions/find-game-text';

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameId: null,
      level1Text: '',
      level2Text: '',
      level3Text: '',
      heroName: 'hero',
      villianName: 'villian',
      cityName: 'city',
      trophy: 'prize',
      enemy: '',
      customText: false,
      canSubmit: false,
      heroNameError: false,
      villianNameError: false,
      cityNameError: false,
      trophyError: false,
      level1TextError: false,
      level2TextError: false,
      level3TextError: false,
    };
  }

  componentDidMount() {
    if (!localStorage.getItem('unfinishedGameId') && (!this.props.location || !this.props.location.state || !this.props.location.state.gameId)) {
      redirectGame(this.props.gameVersionId);
    } else if (localStorage.getItem('unfinishedGameId')) {
      this.setState({
        gameId: localStorage.getItem('unfinishedGameId'),
      });
    } else if (this.props.location.state.gameId) {
      this.setState({
        gameId: this.props.location.state.gameId,
      });
    }
  }

  changeCustomTextHandler(position, value) {
    this.setState({
      [position]: encodeURI(value),
    });
  }

  changeTextHandler(position, value, error) {
    const heroName = position === 'heroName' ? value : this.state.heroName;
    const cityName = position === 'cityName' ? value : this.state.cityName;
    const trophy = position === 'trophy' ? value : this.state.trophy;
    const villianName = position === 'villianName' ? value : this.state.villianName;
    this.setState({
      [position]: encodeURI(value),
      level1Text: encodeURI(`It was a cold and stormy day when ${heroName} set out from ${cityName} in search of ${trophy}. Soon enough the evil ${villianName} was being evil`),
      level2Text: encodeURI(`${heroName} saw ${villianName} hiding near the ${trophy}. "Stop right there ${villianName}!" yelled ${heroName}`),
      level3Text: encodeURI(`${heroName} finally found the ${trophy} In the swamps outside of ${cityName} the evil ${villianName} went home and cried for 6 days. ${heroName} got ice cream`),
      [error]: false,
    });
  }

  validationHandler() {
    const errors = {
      heroNameError: false,
      villianNameError: false,
      cityNameError: false,
      trophyError: false,
      level1TextError: false,
      level2TextError: false,
      level3TextError: false,
      errorExists: false,
    };
    if (!this.state.customText) {
      if (this.state.heroName === '' || this.state.heroName === 'hero') {
        errors.heroNameError = true;
        errors.errorExists = true;
      }
      if (this.state.villianName === '' || this.state.villianName === 'villian') {
        errors.villianNameError = true;
        errors.errorExists = true;
      }
      if (this.state.cityName === '' || this.state.cityName === 'city') {
        errors.cityNameError = true;
        errors.errorExists = true;
      }
      if (this.state.trophy === '' || this.state.trophy === 'prize') {
        errors.trophyError = true;
        errors.errorExists = true;
      }
    } else {
      if (this.state.level1Text === '') {
        errors.level1TextError = true;
        errors.errorExists = true;
      }
      if (this.state.level2Text === '') {
        errors.level2TextError = true;
        errors.errorExists = true;
      }
      if (this.state.level3Text === '') {
        errors.level3TextError = true;
        errors.errorExists = true;
      }
    }
    this.setState({
      heroNameError: errors.heroNameError,
      villianNameError: errors.villianNameError,
      cityNameError: errors.cityNameError,
      trophyError: errors.trophyError,
      level1TextError: errors.level1TextError,
      level2TextError: errors.level2TextError,
      level3TextError: errors.level3TextError,
      enemyError: errors.enemyError,
    });
    if (errors.errorExists) {
      return true;
    }
    return false;
  }

  async submitHandler() {
    if (this.validationHandler()) return;
    await createLevelText(this.state.level1Text, this.state.level2Text, this.state.level3Text, this.state.gameId, this.props.gameVersionId, this.props.redirect);
  }

  handleImageSelect(image) {
    navigate(this.props.redirect, {
      state: {
        villianImage: image,
      },
    });
  }

  render() {
    return (
      <div>
        <h2 className="filled-text-blue" style={{ fontSize: '3rem', textAlign: 'center' }}>Tell Your Story!</h2>
        {!this.state.customText
          ? (
            <table>
              <tbody>
                <tr>
                  {getLevelTextTemplate(this.props.gameVersionId, this.state.heroName, this.state.cityName, this.state.trophy, this.state.villianName)}
                  <th width="50%">
                    <form>
                      {this.state.heroNameError ? <span className="error">Please Enter a Value</span> : null}
                      <br />
                      <input name="heroname" placeholder="Hero's Name" className={`signup-input-element ${this.state.heroNameError ? 'signup-input-element-error' : ''}`} onChange={(e) => this.changeTextHandler('heroName', e.target.value, 'heroNameError')} onClick={() => { this.setState({ heroNameError: false }); }} />
                      <br />
                      {this.state.cityNameError ? <span className="error">Please Enter a Value</span> : null}
                      <br />
                      <input name="cityname" placeholder="City's Name" className={`signup-input-element ${this.state.cityNameError ? 'signup-input-element-error' : ''}`} onChange={(e) => this.changeTextHandler('cityName', e.target.value, 'cityNameError')} onClick={() => { this.setState({ cityNameError: false }); }} />
                      <br />
                      {this.state.trophyError ? <span className="error">Please Enter a Value</span> : null}
                      <br />
                      <input name="trophyname" placeholder="Trophy's Name" className={`signup-input-element ${this.state.trophyError ? 'signup-input-element-error' : ''}`} onChange={(e) => this.changeTextHandler('trophy', e.target.value, 'trophyError')} onClick={() => { this.setState({ trophyError: false }); }} />
                      <br />
                      {this.state.villianNameError ? <span className="error">Please Enter a Value</span> : null}
                      <br />
                      <input name="villianName" placeholder="Villian's Name" className={`signup-input-element ${this.state.villianNameError ? 'signup-input-element-error' : ''}`} onChange={(e) => this.changeTextHandler('villianName', e.target.value, 'villianNameError')} onClick={() => { this.setState({ villianNameError: false }); }} />
                      <br />
                      <br />
                      <button
                        className="main-nav-link"
                        onClick={() => {
                          this.setState({
                            customText: true, level1Text: '', level2Text: '', level3Text: '',
                          });
                        }}
                      >
                        Or Write Your Own level Text
                      </button>
                    </form>
                  </th>
                </tr>
              </tbody>
            </table>
          )
          : (
            <div style={{ paddingBottom: '2rem', marginBottom: '2rem', textAlign: 'center' }}>
              {this.state.level1TextError ? <span className="error">Please Enter a Value</span> : null}
              <br />
              <br />
              <textarea name="custom-level-1-text" id="custom-level-1-text" placeholder="Level 1 Text" className={`signup-input-element ${this.state.level1TextError ? 'signup-input-element-error' : ''}`} onChange={(e) => { this.changeCustomTextHandler('level1text', e.target.value); }} onClick={() => { this.setState({ level1TextError: false }); }} />
              <br />
              <br />
              {this.state.level2TextError ? <span className="error">Please Enter a Value</span> : null}
              <br />
              <br />
              <textarea name="custom-level-2-text" id="custom-level-2-text" placeholder="Level 2 Text" className={`signup-input-element ${this.state.level2TextError ? 'signup-input-element-error' : ''}`} onChange={(e) => { this.changeCustomTextHandler('level2text', e.target.value); }} onClick={() => { this.setState({ level2TextError: false }); }} />
              <br />
              <br />
              {this.state.level3TextError ? <span className="error">Please Enter a Value</span> : null}
              <br />
              <br />
              <textarea name="custom-level-3-text" id="custom-level-3-text" placeholder="Level 3 Text" className={`signup-input-element ${this.state.level3TextError ? 'signup-input-element-error' : ''}`} onChange={(e) => { this.changeCustomTextHandler('level3text', e.target.value); }} onClick={() => { this.setState({ level3TextError: false }); }} />
              <br />
              <br />
              <button className="main-nav-link" onClick={() => { this.setState({ customText: false }, () => { this.changeTextHandler(); }); }}>Use Template</button>
            </div>
          )}
        <div style={{ margin: '2.5rem 0', textAlign: 'center' }}>
          <button onClick={() => this.submitHandler()} className="cta-button">{this.props.redirect ? 'Create Villian' : 'I Love It!'}</button>
          <br />
          <input id="pixopixa-villian-select" type="file" style={{ visibility: 'hidden' }} onChange={(e) => { this.handleImageSelect(e.target.files[0]); }} />
        </div>
      </div>
    );
  }
}

export default Game;
